import axiosIns from '@/libs/axios'

const getAxios = () => axiosIns.get('/login')
const postAxios = data => axiosIns.post('/login', data)
const oneAxios = id => axiosIns.get(`/login/${id}`)
const putAxios = (id, data) => axiosIns.put(`/login/${id}`, data)
const deleteAxios = id => axiosIns.delete(`/login/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
