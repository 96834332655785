<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="sideImg1"
          alt="Login V2"
          :width="250"
        />
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">
          Grow Smart
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Grow Smart!
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert> -->
          <!-- alert -->
          <b-alert
            v-height-fade.appear
            variant="danger"
            :show="showError"
            class="text-left p-1"
          >
            <ul class="list-style-icons mb-0">
              <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
            </ul>
          </b-alert>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-overlay
              :show="showOverLay"
              rounded="sm"
              :opacity="0.5"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  :label="$t('General.Email')"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t('General.Password') }}</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>{{ $t('Login.Forgot Password?') }}</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        :placeholder="$t('General.Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t('Login.Remember Me') }}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  {{ $t('Login.Sign in') }}
                </b-button>
              </b-form>
            </b-overlay>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('Login.New on our platform?') }} </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;{{ $t('Login.Create an account') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('General.Or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox,
  BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BOverlay, BAlert,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import loginService from '@/service/administration/login'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { heightFade } from '@core/directives/animations'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'height-fade': heightFade,
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg1: require('@/assets/images/logo/logo.png'),

      // validation rules
      required,
      email,
      showOverLay: false,
      showError: false,
      apiErrors: [],
      ability: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.login()
        }
      })
    },
    async login() {
      this.showOverLay = true
      await loginService.postAxios({
        email: this.userEmail,
        password: this.password,
      })
        .then(response => {
          const { user } = response.data
          if (user === 0) {
            this.showError = true
            this.apiErrors = this.$t('Login.ErrorLogin')
            this.showOverLay = false
          } else {
            this.showError = false
            useJwt.setToken(response.data.token)
            // useJwt.setRefreshToken(response.data.refreshToken)
            const { permissions } = user.employee.group
            const actionIsAdd = {
              system_admin_header: false,
              accouning_header: false,
              crm_header: false,
              warehouse_header: false,
              sales_header: false,
              purchases_header: false,
            }
            permissions.forEach(item => {
              if (item.module.subsystem_id === 1 && !actionIsAdd.system_admin_header) {
                this.ability.push({ action: 'read', subject: 'SystemAdminHeader' })
                actionIsAdd.system_admin_header = true
              } else if (item.module.subsystem_id === 2 && !actionIsAdd.accouning_header) {
                this.ability.push({ action: 'read', subject: 'AccouningHeader' })
                actionIsAdd.accouning_header = true
              } else if (item.module.subsystem_id === 3 && !actionIsAdd.crm_header) {
                this.ability.push({ action: 'read', subject: 'CRMHeader' })
                actionIsAdd.crm_header = true
              } else if (item.module.subsystem_id === 4 && !actionIsAdd.warehouse_header) {
                this.ability.push({ action: 'read', subject: 'WarehouseHeader' })
                actionIsAdd.warehouse_header = true
              } else if (item.module.subsystem_id === 5 && !actionIsAdd.sales_header) {
                this.ability.push({ action: 'read', subject: 'SalesHeader' })
                actionIsAdd.sales_header = true
              } else if (item.module.subsystem_id === 6 && !actionIsAdd.purchases_header) {
                this.ability.push({ action: 'read', subject: 'PurchasesHeader' })
                actionIsAdd.purchases_header = true
              }
              this.ability.push({ action: item.action.name_en, subject: item.module.name_en })
            })
            this.ability.push({ action: 'read', subject: 'dashboard' })
            this.$ability.update(this.ability)
            this.$router.replace(getHomeRouteForLoggedInUser('admin'))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${user.name || user.name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `${this.$t('Login.SuccessfullyLogin')} ${user.name}. ${this.$t('Login.SuccessfullyLogin2')}`,
                  },
                })
              })
            user.ability = this.ability
            localStorage.setItem('userData', JSON.stringify(user))
            this.showOverLay = false
          }
          // ? This is just for demo purpose as well.
          // ? Because we are showing eCommerce app's cart items count in navbar
          // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
        })
        .catch(error => {
          this.showError = true
          this.apiErrors = error
          this.showOverLay = false
          // this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
